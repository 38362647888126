import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeAuthToken } from "../../../helpers";
import { resetOnLoadState } from "../../../store/onLoadSlice";
import { resetMobileViewState } from "../../../store/mobileViewSlice";
import { resetMode } from "../../../store/modeSlice";
import { resetWatchlistState } from "../../../store/watchlistSlice";
import { resetSymbols } from "../../../store/symbolsSlice";
import { resetActiveIndicator } from "../../../store/indicators/activeIndicator";
import { resetIndicators } from "../../../store/indicators/indicatorsSlice";
import { resetUserState } from "../../../store/userSlice";
import { changeTab } from "../../../store/mobileViewSlice";
import { FaChartLine, FaLanguage } from "react-icons/fa";
import { TbCalendarStats } from "react-icons/tb";
import { IoLogOut, IoInformationCircleOutline } from "react-icons/io5";
import { MdContactPhone } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { PiNewspaperBold } from "react-icons/pi";
import { RxCross1 } from "react-icons/rx";
import { useQueryClient } from "@tanstack/react-query";
import { RiLuggageDepositFill } from "react-icons/ri";
import { BiMoneyWithdraw } from "react-icons/bi";
import { getServerDetailsByGroup } from "../../ATVC/helpers";

const SideBarMob = ({ isVisible, setIsVisible }) => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(["user"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [serverDetails, setServerDetails] = useState({
    SERVER_NAME: "Beta Trader (BT11)",
    SEVER_SUPPORT_EMAIL: "support@betatrader11.com",
    SEVER_SUPPORT_PHONE: "+91 9999999999",
    ICON: "icon.png",
  });

  useEffect(() => {
    if (data?.group?.Name) {
      const details = getServerDetailsByGroup(data?.group?.Name);
      setServerDetails(details);
    }
  }, [data]);

  const handleCloseSidebar = () => {
    setIsVisible(false);
  };

  const handleLogOut = () => {
    dispatch(resetOnLoadState());
    dispatch(resetMobileViewState());
    dispatch(resetMode());
    dispatch(resetWatchlistState());
    dispatch(resetSymbols());
    dispatch(resetActiveIndicator());
    dispatch(resetIndicators());
    dispatch(resetUserState());
    localStorage.removeItem('baseUrl')
    removeAuthToken("AUTH-TOKEN");
    navigate("/login");
  };

  const handleExternalLink = (url) => {
    navigate(url, { state: { previousPage: window.location.pathname } });
  };

  const menuItems = [
    ...(data?.user && data?.user.name !== "DEMO USER"
      ? [
          {
            icon: RiLuggageDepositFill,
            text: "Deposit",
            action: () => handleExternalLink("/deposit"),
          },
          {
            icon: BiMoneyWithdraw,
            text: "Withdraw",
            action: () => handleExternalLink("/withdraw"),
          },
        ]
      : []),
    {
      icon: FaChartLine,
      text: "Trade",
      action: () => dispatch(changeTab("wallet")),
    },
    {
      icon: PiNewspaperBold,
      text: "News",
      action: () => dispatch(changeTab("news")),
    },
    { icon: FaLanguage, text: "Mailbox" },
    { icon: MdContactPhone, text: "Journal", Link: "/journal" },
    { icon: CiSettings, text: "Settings" },
    {
      icon: TbCalendarStats,
      text: "Economic Calendar",
      action: () => dispatch(changeTab("calender")),
    },
    { icon: IoInformationCircleOutline, text: "About", Link: "/about" },
  ];

  return (
    <div
      className={`fixed inset-0 z-40 transition-opacity duration-300 ease-in-out ${
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        onClick={handleCloseSidebar}
      ></div>
      <aside
        className={`relative flex flex-col max-w-xs w-4/5 bg-white shadow-xl transition-transform duration-300 ease-in-out transform ${
          isVisible ? "translate-x-0" : "-translate-x-full"
        } h-full`}
      >
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <div className="flex items-center space-x-3">
            <img className="w-10 h-10" src={serverDetails?.ICON} alt="Logo" />
            <div className="text-sm">
              <h6 className="font-bold">{serverDetails?.SERVER_NAME}</h6>
              <p className="text-gray-600">{data?.user?.email}</p>
            </div>
          </div>
          <button
            onClick={handleCloseSidebar}
            className="text-gray-500 hover:text-gray-700"
          >
            <RxCross1 size={20} />
          </button>
        </div>
        <nav className="flex-1 px-4 py-4 overflow-y-auto">
          <ul className="space-y-2">
            {menuItems.map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => {
                    if (item.action) item.action();
                    handleCloseSidebar();
                  }}
                  className="flex items-center w-full p-2 text-gray-700 rounded-lg hover:bg-gray-100 transition-colors duration-150 ease-in-out"
                >
                  {item.Link ? (
                    <Link to={item.Link} className="flex items-center w-full">
                      <item.icon size={20} className="mr-3" />
                      <span>{item.text}</span>
                    </Link>
                  ) : (
                    <>
                      <item.icon size={20} className="mr-3" />
                      <span>{item.text}</span>
                    </>
                  )}
                </button>
              </li>
            ))}
            <li>
              <button
                onClick={handleLogOut}
                className="flex items-center w-full p-2 text-red-600 rounded-lg hover:bg-red-100 transition-colors duration-150 ease-in-out"
              >
                <IoLogOut size={20} className="mr-3" />
                <span>Logout</span>
              </button>
            </li>
          </ul>
        </nav>
      </aside>
    </div>
  );
};

export default SideBarMob;
