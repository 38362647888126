import { io } from "socket.io-client";
import { apiUrl } from "../api/apiClient";
import { getBaseUrlLocalStorage } from "../helpers";


const baseURL= getBaseUrlLocalStorage("baseUrl")

console.log("baseURL",baseURL)

const socket = io(baseURL, {
  transports: ["websocket"],
  withCredentials: false,
  reconnection: true,
});


export default socket;


