export const removeAuthToken = () => {
  try {
    localStorage.removeItem("AUTH-TOKEN");
    localStorage.setItem("isAuthenticated", false);
  } catch (error) {
    console.error("Error removing AUTH-TOKEN:", error);
  }
};

export const getAuthToken = () => {
  return localStorage.getItem("AUTH-TOKEN");
};
export const setUserChangeTo = (data) => {
  return localStorage.setItem("ACTIVE-USER", JSON.stringify(data));
};

export const getActiveUser = () => {
  return JSON.parse(localStorage.getItem("ACTIVE-USER"));
};

export const setUserCredentials = (credentials) => {
  let storedCredentials = JSON.parse(localStorage.getItem("AUTH-CARD")) || [];

  // Check if credentials with the same userId already exist
  const index = storedCredentials.findIndex(
    (cred) => cred.userId === credentials.userId
  );

  if (index !== -1) {
    // Replace the existing credentials
    storedCredentials[index] = credentials;
  } else {
    // Add new credentials to the array
    storedCredentials.push(credentials);
  }

  localStorage.setItem("AUTH-CARD", JSON.stringify(storedCredentials));
};

export const removeUserCredential = (userId) => {
  // Retrieve the stored credentials
  let storedCredentials = JSON.parse(localStorage.getItem("AUTH-CARD")) || [];

  // Filter out the credentials with the matching userId
  storedCredentials = storedCredentials.filter(cred => cred.userId !== userId);

  // Update the localStorage with the new array
  localStorage.setItem("AUTH-CARD", JSON.stringify(storedCredentials));
};

export const getUserCredentials = () => {
  const authCard = JSON.parse(localStorage.getItem("AUTH-CARD"));

  return authCard;
};

export const removeUserCredentials = () => {
  localStorage.removeItem("AUTH-CARD");
};

export const setAuthCred = (data) => {
  console.log(data);
  setAuthToken(data?.token);
  setUserCredentials({
    userId: data?.user?.userId,
    password: data?.user?.password,
    name: data?.user?.name,
    type: data?.user?.type,
  });
};

export const setAuthToken = (token) => {
  localStorage.setItem("AUTH-TOKEN", token);
  localStorage.setItem("isAuthenticated", true);
  if (localStorage.getItem("AUTH-TOKEN")) {
    return true;
  } else {
    return false;
  }
};

export const getCurrentDateFormatted = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const mapTypeToString = (type) => {
  switch (type) {
    case 0:
      return "Sell";
    case 1:
      return "Buy";
    case 2:
      return "Buy Limit";
    case 3:
      return "Sell Limit";
    case 4:
      return "Buy Stop";
    case 5:
      return "Sell Stop";
    case 6:
      return "Buy Stop Limit";
    case 7:
      return "Sell Stop Limit";
    default:
      return "Unknown";
  }
};

export const stringToMapType = (str) => {
  switch (str) {
    case "Sell":
      return 0;
    case "Buy":
      return 1;
    case "Buy Limit":
      return 2;
    case "Sell Limit":
      return 3;
    case "Buy Stop":
      return 4;
    case "Sell Stop":
      return 5;
    case "Buy Stop Limit":
      return 6;
    default:
      return -1;
  }
};

export const positionFields = [
  "ID",
  "SYMBOL",
  "TICKET",
  "PRICE",
  "TIME",
  "TYPE",
  "VOLUME",
  "S/L",
  "T/P",
  "CURRENT PRICE",
  "SWAP",
  // "UPDATED TIME",
  // "COMMISSION",
  "P/L",
  "COMMENT",
  "ACTION",
];

export const orderFields = [
  "ID",
  "SYMBOL",
  "TICKET",
  "PRICE",
  "TIME",
  "TYPE",
  "VOLUME",
  "S/L",
  "T/P",
  "STATE",
  "COMMENT",
];

export const dealsField = [
  "ID",
  "SYMBOL",
  "TICKET",
  "ORDER",
  "TIME",
  "PRICE",
  "TYPE",
  "DIRECTION",
  "VOLUME",
  "SWAP",
  "COMMISSION",
  "P/L",
  "COMMENT",
];

export const closedPositionField = [
  "ID",
  "SYMBOL",
  "TICKET",
  "OPEN PRICE",
  "OPEN TIME",
  "TYPE",
  "VOLUME",
  "CLOSED PRICE",
  "CLOSE TIME",
  "S/L",
  "T/P",
  "SWAP",
  "COMMISSION",
  "P/L",
  "COMMENT",
];

export const mapOrderData = (data) => {
  if (data.length === 0) return null;

  const mappedOrders = data?.map((order) => ({
    id: order._id || "",
    symbol: order.symbol || "",
    ticket: order.ticket || "",
    price: order.price || "",
    createdAt: formatTimeToSeconds(order.createdAt) || "",
    type: mapTypeToString(order.type) || "",
    volume: order.initialVolume || "",
    stopLoss: fixedNumber(order?.stopLoss) || "",
    takeProfit: fixedNumber(order?.takeProfit) || "",
    status: order.status === 1 ? "Filled" : "Cancelled",
    comment: order.comment || "",
  }));

  return mappedOrders.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );
};

export const mapDealsData = (data) => {
  if (data.length === 0) return null;

  const mappedDeals = data?.map((deal) => ({
    id: deal._id || "",
    symbol: deal.symbol || "",
    ticket: deal.ticket || "",
    order: deal.order || "",
    createdAt: formatTimeToSeconds(deal.createdAt) || "",
    price: deal.price || "",
    type: mapTypeToString(deal.type) || "",
    direction: deal.action === 0 ? "OUT" : "IN",
    volume: deal.volume || "",
    swap: deal.swap?.toFixed(2) || "",
    commission:  - deal.comission?.toFixed(2) || "",
    profit: deal.profit?.toFixed(2) || "",
    comment: deal.comment || "",
  }));

  return mappedDeals.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );
};

export const mapRunningPositions = (data) => {
  if (data.length === 0) return null;

  const mappedPositions = data
    ?.map((position) => {
      if (position?.status === 1) {
        return {
          id: position._id || "",
          symbol: position.symbol || "",
          ticket: position.ticket || "",
          price: position.price || "",
          createdAt: formatTimeToSeconds(position.createdAt) || "",
          type: mapTypeToString(position.type) || "",
          volume: position.volume || "",
          stopLoss: fixedNumber(position?.stopLoss) || "",
          takeProfit: fixedNumber(position?.takeProfit) || "",
          currentPrice: fixedNumber(position.lastPrice) || " ",
          swap: position.swap?.toFixed(2) || "",
          // commission: "-" + position.comission || " ",
          profit:
            position.status === 1 ? position.profit?.toFixed(2) : "Placed",
          comment: position.comment || " ",
        };
      }
      return null;
    })
    .filter(Boolean);

  return mappedPositions.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );
};

export const mapPendingPositions = (data) => {
  if (data.length === 0) return null;

  const mappedPositions = data
    ?.map((position) => {
      if (position?.status === 0) {
        return {
          id: position._id || "",
          symbol: position.symbol || "",
          ticket: position.ticket || "",
          price: position.price || "",
          createdAt: formatTimeToSeconds(position.createdAt) || "",
          type: mapTypeToString(position.type) || "",
          volume: position.volume || "",
          stopLoss: fixedNumber(position?.stopLoss) || "",
          takeProfit: fixedNumber(position?.takeProfit) || "",
          currentPrice: fixedNumber(position.lastPrice) || " ",
          swap: position.swap?.toFixed(2) || "",
          // commission: "-" + position.comission || " ",
          profit:
            position.status === 1 ? position.profit?.toFixed(2) : "Placed",
          comment: position.comment || " ",
        };
      }
      return null;
    })
    .filter(Boolean);

  return mappedPositions.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );
};

// export const mapPendingPositions = (data) => {
//   if (data.length === 0) return null;

//   const mappedPendingPositions = data
//     ?.map((position) => {
//       if (position.status === 0) {
//         return {
//           id: position._id || "",
//           symbol: position.symbol || "",
//           ticket: position.ticket || "",
//           createdAt: formatTimeToSeconds(position.createdAt) || "",
//           type: mapTypeToString(position.type) || "",
//           volume: position.volume,
//           price: position.price || "",
//           stopLoss: fixedNumber(position?.stopLoss) || "",
//           takeProfit: fixedNumber(position?.takeProfit) || "",
//           swap: position.swap?.toFixed(2) || "",
//           currentPrice: position.lastPrice,
//           updatedAt: formatTimeToSeconds(position.updatedAt) || "",
//           commission: "-" + position.comission || " ",

//           comment: position.comment || "",
//           profit: "Placed",
//         };
//       }
//       return null;
//     })
//     .filter(Boolean);

//   return mappedPendingPositions.sort((a, b) => {
//     const dateA = new Date(a.createdAt);
//     const dateB = new Date(b.createdAt);
//     return dateA - dateB;
//   });
// };

export const mapClosedPosition = (data) => {
  if (!data || data.length === 0) return [];

  const mappedClosedPosition = data.map((position) => ({
    id: position._id || "",
    symbol: position.symbol || "",
    ticket: position.ticket || "",
    price: position.price || "",
    createdAt: formatTimeToSeconds(position.createdAt) || "",
    type: mapTypeToString(position.type) || "",
    volume: position.volume.toFixed(2) || "",
    closePrice: position.closePrice || "",
    closeTime: formatTimeToSeconds(position.closeTime) || "",
    stopLoss: fixedNumber(position?.stopLoss) || "",
    takeProfit: fixedNumber(position?.takeProfit) || "",
    swap: position.swap?.toFixed(2) || "",
    commission: "-" + position.comission.toFixed(2) || " ",
    profit: position.profit?.toFixed(2) ?? "",
    comment: position.comment || "",
  }));

  return mappedClosedPosition.sort(
    (a, b) => new Date(a.closeTime) - new Date(b.closeTime)
  );
};

export const fixedNumber = (number) => {
  const integerPart = Math.floor(number);
  const beforeDecimal =
    integerPart === 0 ? 1 : Math.floor(Math.log10(Math.abs(integerPart))) + 1;
  let afterDecimal = 6 - beforeDecimal;

  // Ensure afterDecimal is within valid range for toFixed
  if (afterDecimal < 0) {
    afterDecimal = 0;
  }

  return number?.toFixed(afterDecimal);
};

export const fN = (number) => {
  const integerPart = Math.floor(number);
  const beforeDecimal =
    integerPart === 0 ? 1 : Math.floor(Math.log10(Math.abs(integerPart))) + 1;
  const afterDecimal = 8 - beforeDecimal;
  return number?.toFixed(afterDecimal);
};

export const toggleFullScreen = () => {
  const documentElement = document.documentElement;

  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    if (documentElement.requestFullscreen) {
      documentElement.requestFullscreen();
    } else if (documentElement.mozRequestFullScreen) {
      documentElement.mozRequestFullScreen();
    } else if (documentElement.webkitRequestFullscreen) {
      documentElement.webkitRequestFullscreen();
    } else if (documentElement.msRequestFullscreen) {
      documentElement.msRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
};

export const formatTimeToSeconds = (dateTimeString) => {
  if (!dateTimeString) return "";
  const date = new Date(dateTimeString);
  return date.toISOString().slice(0, 19).replace("T", " ");
};

export function countDecimalPlaces(number) {
  const numberAsString = number.toString();

  if (numberAsString.includes(".")) {
    return numberAsString.split(".")[1].length;
  } else {
    return 0;
  }
}

export function divideByDecimalPlaces(number) {
  const decimalPlaces = countDecimalPlaces(number);
  return 1 / Math.pow(10, decimalPlaces);
}

export const setBaseUrlLocalStorage = (baseURL) => {
  return localStorage.setItem('baseUrl',baseURL);
};

export const getBaseUrlLocalStorage = () => {
  return localStorage.getItem("baseUrl");
};