import axios from "axios";
import axiosRetry from "axios-retry";
import { getAuthToken, getBaseUrlLocalStorage } from "../helpers/index";

// export const apiUrl = "https://app.betatrader11.com/";   
export const socketClient = "https://server.skycapmarket.com/"

// export const apiUrl = "http://185.199.53.84:8080/";

export let apiUrl;



const apiClient = axios.create({
  // baseURL: apiUrl,
  // timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

// axiosRetry(apiClient, {
//   // retries: 1,
//   // retryDelay: axiosRetry.exponentialDelay,
//   // shouldResetTimeout: true,
// });




apiClient.interceptors.request.use(async (config) => {
  config.headers["Accept"] = "application/json";
  return config;
});

apiClient.interceptors.request.use(
  async (config) => {
    apiUrl = getBaseUrlLocalStorage("baseUrl") || "https://app.betatrader11.com/"  // Fallback to default API URL
    console.log("newBaseUrl", apiUrl)
    config.baseURL = apiUrl; // Set baseURL dynamically on each request
    config.headers["Accept"] = "application/json";

    const token = getAuthToken("AUTH-TOKEN");
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// apiClient.interceptors.request.use(
//   async (config) => {
//     // console.log("Request interceptor config:", config);

//     const token = getAuthToken("AUTH-TOKEN");
//     if (token) {
//       if (!config.headers) {
//         config.headers = {};
//       }
//       config.headers["Authorization"] = `${token}`;
//     }

//     return config;
//   },
//   (error) => {
//     // console.log("REQUEST ERROR", error);
//     return Promise.reject(error);
//   }
// );

apiClient.interceptors.request.use(
  (config) => {
    // console.log(
    //   `[Axios Request] ${config.method.toUpperCase()} ${config.url} `,
    //   config.data
    // );
    return config;
  },
  (error) => {
    console.error("[Axios Request Error]", error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    // console.log(
    //   `[Axios Response] ${response.config.method.toUpperCase()} ${response.config.url
    //   }`,
    //   response.data
    // );
    return response;
  },
  (error) => {
    console.error("[Axios Response Error]", error);
    return Promise.reject(error);
  }
);

export default apiClient;
