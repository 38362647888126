// import React, { useState, useEffect, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   getActiveUser,
//   setAuthToken,
//   setUserCredentials,
// } from "../../../helpers";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import { postLogin } from "../../../api/queryFunctions";
// import { VscEye, VscEyeClosed } from "react-icons/vsc";

// const Login = () => {
//   const [showPassword, setShowPassword] = useState(false);
//   const [error, setError] = useState("");

//   const inputRefs = useRef({
//     userId: null,
//     password: null
//   });

//   const { mutate: login, data: res, isLoading } = useMutation({
//     mutationKey: "login",
//     mutationFn: (data) => postLogin(data),
//     onSettled: (data) => {
//       setError(data?.data?.message);
//     },
//   });

//   const navigate = useNavigate();
//   const queryClient = useQueryClient();

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     const userId = inputRefs.current.userId?.value.trim();
//     const password = inputRefs.current.password?.value.trim();

//     if (userId.length > 0 && password.length > 0) {
//       login({ userId, password });
//     } else {
//       setError("Please enter valid credentials");
//     }
//   };

//   useEffect(() => {
//     const cred = getActiveUser();
//     if (cred) {
//       if (inputRefs.current.userId) inputRefs.current.userId.value = cred?.userId || "";
//       if (inputRefs.current.password) inputRefs.current.password.value = cred?.password || "";
//     }
//   }, []);

//   useEffect(() => {
//     const queryKeys = [
//       "user",
//       "positions",
//       "closedPositions",
//       "orders",
//       "deals",
//     ];
//     if (res?.data?.token) {
//       setAuthToken(res.data.token);
//       setUserCredentials({
//         userId: res.data.user.email,
//         password: inputRefs.current.password?.value || "",
//         name: res.data.user.name,
//       });
//       queryClient.invalidateQueries(queryKeys);
//       navigate("/");
//     }
//   }, [navigate, res, queryClient]);

//   const togglePasswordVisibility = () => {
//     setShowPassword((prevShowPassword) => !prevShowPassword);
//   };

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-300 to-blue-700 text-gray-900">
//       <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
//         <h2 className="text-2xl font-bold mb-8 text-center text-blue-700">
//           Login
//         </h2>
//         <form onSubmit={handleLogin}>
//           <div className="mb-4">
//             <label className="block text-gray-700 mb-2" htmlFor="username">
//               User ID
//             </label>
//             <input
//               type="text"
//               id="username"
//               ref={(el) => (inputRefs.current.userId = el)}
//               autoComplete="username"
//               className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 transition duration-300 text-gray-700"
//             />
//           </div>
//           <div className="mb-6 relative">
//             <label className="block text-gray-700 mb-2" htmlFor="password">
//               Password
//             </label>
//             <input
//               type={showPassword ? "text" : "password"}
//               id="password"
//               ref={(el) => (inputRefs.current.password = el)}
//               autoComplete="current-password"
//               className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500 transition duration-300 text-gray-700"
//             />
//             <button
//               type="button"
//               onClick={togglePasswordVisibility}
//               className="absolute inset-y-0 right-3 top-1/2 transform  focus:outline-none"
//             >
//               {showPassword ? (
//                 <VscEye color="gray" size={20} />
//               ) : (
//                 <VscEyeClosed color="gray" size={20} />
//               )}
//             </button>
//           </div>
//           {error && <p className="text-red-500 text-center mb-4">{error}</p>}
//           <button
//             type="submit"
//             disabled={isLoading}
//             className={`w-full py-3 rounded-lg transition duration-300 ease-in-out ${
//               isLoading
//                 ? "bg-gradient-to-r from-green-400 to-blue-500 cursor-not-allowed"
//                 : "bg-gradient-to-r from-green-300 to-blue-700  text-white"
//             }`}
//           >
//             {isLoading ? "Processing..." : "Login"}
//           </button>
//         </form>
//         <div className="text-center mt-4">
//           <span className="text-gray-700">Don't have an account?</span>
//           <Link
//             to="/signup"
//             className="text-blue-600 hover:text-blue-700 transition duration-300 ease-in-out ml-2"
//           >
//             Register Here
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;

"use client"

import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { getActiveUser, setAuthToken, setBaseUrlLocalStorage, setUserCredentials } from "../../../helpers"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { postLogin } from "../../../api/queryFunctions"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import axios from "axios"






export default function Login() {
  
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState("")
  const [serverIp, setServerIp] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIP, setSelectedIP] = useState("");
  const [selectedIPUrl, setSelectedIPUrl] = useState("");



  const inputRefs = useRef({
    userId: null,
    password: null,
  })

  const { mutate: login, data: res, isLoading } = useMutation({
    mutationKey: "login",
    mutationFn: (data) => postLogin(data),
    onSettled: (data) => {
      setError(data?.data?.message)
      if (data?.data.status == true) {

        setTimeout(() => {
          window.location.reload()
        }, 200)

      }
    },
  })

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const handleLogin = async (e) => {
    e.preventDefault()
    const userId = inputRefs.current.userId?.value.trim()
    const password = inputRefs.current.password?.value.trim()

    if (userId.length > 0 && password.length > 0) {
      login({ userId, password })

    } else {
      setError("Please enter valid credentials")
    }
  }

  useEffect(() => {
    const cred = getActiveUser()
    if (cred) {
      if (inputRefs.current.userId) inputRefs.current.userId.value = cred?.userId || ""
      if (inputRefs.current.password) inputRefs.current.password.value = cred?.password || ""
    }

  }, [])

  useEffect(() => {
    const queryKeys = ["user", "positions", "closedPositions", "orders", "deals"]
    if (res?.data?.token) {
      setAuthToken(res.data.token)
      setUserCredentials({
        userId: res.data.user.email,
        password: inputRefs.current.password?.value || "",
        name: res.data.user.name,
        type: res.data.user.type,
      })
      queryClient.invalidateQueries(queryKeys)
      navigate("/")
    }
  }, [navigate, res, queryClient])

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }



  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedIP(""); // Reset selected IP when search term changes
  };

  const handleSelect = (name) => {
    setSelectedIP(name);
    setSearchTerm(""); // Clear searchTerm to hide the suggestion list
  };
  const handleSelectUrl = (url) => {
    setSelectedIPUrl(url)
    setBaseUrlLocalStorage(url)

  }

  const getBaseUrl = async () => {
    try {
      const resp = await axios.get('https://piptrade.org/server.php');
      const data = resp.data;
      setServerIp(data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  // const filteredServers = serverIp.filter((item) =>
  //   item.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // Only filter servers if searchTerm is 3 or more characters
  const filteredServers =
    searchTerm.length >= 3
      ? serverIp.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      : [];


  const handleSetDemoUrl = () => {
    localStorage.setItem('baseUrl', "https://demo.rapidtrader.net/")
  }



  useEffect(() => {
    // setBaseUrlLocalStorage(selectedIPUrl)
    getBaseUrl();
  }, [selectedIPUrl]);


  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-300 to-blue-700 ">
      <div className="w-full max-w-md bg-white rounded-lg p-8">
        <h2 className="text-2xl font-bold mb-6 text-center text-blue-700">Login</h2>
        <form onSubmit={handleLogin} className="space-y-4">

          <div>
            {/* Search Input */}
            <div className="mb-4 relative">
              <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-1">
                Search Server Name
              </label>
              <input
                required
                type="text"
                autoComplete="off"
                id="search"
                value={selectedIP || searchTerm} // Show selected IP if present
                onChange={handleSearchChange}
                placeholder="Type to search..."
                className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm placeholder-gray-400
            focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              />

              {/* Suggestions List */}
              {searchTerm && filteredServers.length > 0 && (
                <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-48 overflow-y-auto">
                  {filteredServers.map((item) => (
                    <li
                      key={item.url}
                      onClick={() => {
                        handleSelect(item.name)
                        handleSelectUrl(item.url)
                      }
                      }
                      className="px-3 py-2 cursor-pointer hover:bg-blue-100"
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>





          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
              User ID
            </label>
            <input
              type="text"
              id="username"
              ref={(el) => (inputRefs.current.userId = el)}
              autoComplete="username"
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm  placeholder-gray-400
                focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              ref={(el) => (inputRefs.current.password = el)}
              autoComplete="current-password"
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm  placeholder-gray-400
                focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 flex items-center pr-3 pt-5"
            >
              {showPassword ? <VscEye className="text-gray-400" size={20} /> : <VscEyeClosed className="text-gray-400" size={20} />}
            </button>
          </div>
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full py-2 px-4 border border-transparent rounded-md  text-sm font-medium text-white 
              ${isLoading
                ? "bg-gradient-to-r from-green-400 to-blue-500 cursor-not-allowed"
                : "bg-gradient-to-r from-green-300 to-blue-700 hover:from-green-400 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              }`}
          >
            {isLoading ? "Processing..." : "Login"}
          </button>
        </form>
        <div className="mt-6 text-center">
          <span className="text-sm text-gray-600">Don't have an account?</span>
          <Link to="/signup" className="ml-1 text-sm text-blue-600 hover:text-blue-700 hover:underline" onClick={handleSetDemoUrl}>
            Register Here
          </Link>
        </div>
      </div>
    </div>
  )
}